import { userIsAccountCreator } from '#app/utilities/accountCreatorUtils'
import { isAfter, isBefore, isEqual } from '#services/dateandtime'
import { RootState } from '#state/types'
import {
    AdDto,
    BankDto,
    DepositDto,
    InterestRateChangeDto,
    OrganisationUserInviteDto,
    SettlementAccountDto,
    TaskDto,
} from '@fixrate/fixrate-query'
import { ShareClassInfo } from '@fixrate/fixrate-report'
import subDays from 'date-fns/subDays'
import { createSelector } from 'reselect'

const productsFilterSelector = (state: RootState) => state.marketplacefilter.activeFilter.products
const inputFilterSelector = (state: RootState) => state.marketplacefilter.activeFilter
const userAssociationsSelector = (state: RootState) => state?.session?.associations
const userAssociationSelector = (state: RootState) => state?.session?.association
const adsSelector = (state: RootState) => state.ads
const ordersDataSelector = (state: RootState) => state.orders
const depositorsDataSelector = (state: RootState) => state.depositors
const depositorDataSelector = (state: RootState) => state.depositor
const depositorNamesDataSelector = (state: RootState) => state.depositorNames
const depositsSelector = (state: RootState) => state.deposits
const sessionSelector = (state: RootState) => state.session
const banksDataSelector = (state: RootState) => state.banks
const bankDataSelector = (state: RootState) => state.bank
const partnerDataSelector = (state: RootState) => state.partner
const bankDocumentsDataSelector = (state: RootState) => state.bankDocuments
const documentsSelector = (state: RootState) => state.documents
const portfolioFilterSelector = (state: RootState) => state.portfolioFilter
const interestRateChangeSelector = (state: RootState) => state.interestRateChange
const identificationDocumentIdSelector = (state: RootState) =>
    state.identificationDocument && state.identificationDocument.id

export const loaded = createSelector(
    (state: RootState) => state.loaded,
    (loaded) => loaded
)

export const isLoggedIn = createSelector(sessionSelector, (s) => s.authenticated)

export const depositsByIdSelector = createSelector(depositsSelector, (deposits) =>
    deposits.reduce(
        (deposits, deposit) => ({
            ...deposits,
            [deposit.id]: deposit,
        }),
        {}
    )
)

export const hasProductFilter = createSelector(productsFilterSelector, (productsFilter) =>
    Object.values(productsFilter).some(Boolean)
)

export const bankCountSelector = createSelector(
    banksDataSelector,
    (banks) => Object.values(banks).filter((b) => b.enabled).length
)

export const userAssociationMap = createSelector(userAssociationsSelector, (userAssociations) =>
    userAssociations?.reduce((associations, association) => {
        if (!association?.organisation) {
            return associations
        }

        if (association?.organisation?.id) {
            associations[association.organisation.id] = association
        }

        return associations
    }, {})
)

// Returns true if the currently logged-in user has the role DEPOSITOR_SIGN_AUTHORIZATION and no other roles in any depositor
export const hasOnlySignAuthorizationRole = createSelector(userAssociationsSelector, (userAssociations) => {
    const roles = userAssociations?.filter((ass) => ass.organisation && ass.roles).flatMap((ass) => ass.roles)

    return !!roles && roles.length > 0 && roles.every((role) => role === 'DEPOSITOR_SIGN_AUTHORIZATION')
})

export const hasSignAuthorizationRole = createSelector(userAssociationsSelector, (userAssociations) => {
    const roles = userAssociations?.filter((ass) => ass.organisation && ass.roles).flatMap((ass) => ass.roles)

    return !!roles && roles.length > 0 && roles.find((role) => role === 'DEPOSITOR_SIGN_AUTHORIZATION')
})

export const hasViewDepositorRole = createSelector(userAssociationsSelector, (userAssociations) => {
    const roles = userAssociations?.filter((ass) => ass.organisation && ass.roles).flatMap((ass) => ass.roles)

    return roles && roles.length > 0 && !roles.every((role) => role === 'DEPOSITOR_SIGN_AUTHORIZATION')
})

export const mappedAdsSelector = createSelector(adsSelector, ordersDataSelector, (ads, orders) => {
    return ads.map((ad) => ({
        ...ad,
        hasOrder: orders.find((order) => order.orderState !== 'CANCELED' && order.ad.id === ad.id) != null,
    }))
})

export const filteredAndSortedAds = createSelector(
    mappedAdsSelector,
    banksDataSelector,
    hasProductFilter,
    productsFilterSelector,
    inputFilterSelector,
    (state: RootState) => state.adStatus,
    (ads, banks, hasProductFilter, productsFilter, activeFilter, adStatus) => {
        let interestSort = 0
        return ads
            .reduce((ads, ad) => {
                if (!adStatus[ad.id]?.active) {
                    return ads
                }

                if (hasProductFilter) {
                    if (!productsFilter[ad.productId]) {
                        return ads
                    }
                }

                if (activeFilter.minDepositAmount < 0) {
                    return ads
                }

                if (!(activeFilter.minDepositAmount <= ad.max && activeFilter.minDepositAmount <= ad.remaining)) {
                    return ads
                }

                if (banks[ad.bankId]?.totalAssets < activeFilter.totalAssets) {
                    return ads
                }

                ads.push(ad)

                return ads
            }, [])
            .sort((a, b) => {
                interestSort = b.interest - a.interest
                return interestSort !== 0
                    ? interestSort
                    : new Date(a.published).getTime() - new Date(b.published).getTime()
            }) as AdDto[]
    }
)

export const myBankAds = createSelector(mappedAdsSelector, userAssociationSelector, (ads, association) => {
    if (!association) {
        return []
    }
    const id = association.organisation?.id
    return ads
        .filter((ad) => ad.bankId === id)
        .slice()
        .sort(
            (a, b) =>
                !!a.published && !!b.published && new Date(b.published).getTime() - new Date(a.published).getTime()
        )
})

export const allDocumentsAreSigned = createSelector(
    ordersDataSelector,
    documentsSelector,
    (orders, documents) => (orderId: string) => {
        const order = orders.find((o) => o.id === orderId)
        if (!order) return false
        return order.documents
            .map((documentId) => documents[documentId])
            .filter((document) => !!document)
            .filter(
                (document) =>
                    document.documentType === 'ACCOUNT_AGREEMENT' || document.documentType === 'ACCOUNT_TERMINATION'
            )
            .every((document) => document.signedByAll)
    }
)

export const ordersWithAd = createSelector(ordersDataSelector, (orders) => {
    return orders.filter((order) => order.ad)
})

export const ordersWithStateInitialProcessing = createSelector(ordersDataSelector, (orders) =>
    orders.filter((order) => order.orderState === 'INITIAL_PROCESSING')
)

export const ordersWithStateReadyForApproval = createSelector(ordersDataSelector, (orders) =>
    orders.filter((order) => order.orderState === 'READY_FOR_APPROVAL')
)

export const ordersWithStateReadyForSigning = createSelector(ordersDataSelector, (orders) =>
    orders.filter((order) => order.orderState === 'READY_FOR_SIGNING')
)

export const ordersWithStateReadyForTransaction = createSelector(ordersDataSelector, (orders) =>
    orders.filter((order) => order.orderState === 'READY_FOR_TRANSACTION')
)

export const ordersWithStateReadyForTransactionConfirmation = createSelector(ordersDataSelector, (orders) =>
    orders.filter((order) => order.orderState === 'READY_FOR_TRANSACTION_CONFIRMATION')
)

export const ordersWithStateCancelled = createSelector(
    ordersDataSelector,
    (state: RootState) => state.history,
    (orders, history) =>
        orders
            .filter((order) => order.orderState === 'CANCELED')
            .map((order) => ({
                ...order,
                lastHistoryAction: history?.filter(
                    (p) => p.processType === 'ORDER' && p.processId === order.id && p.type === 'ORDER_CANCELLED'
                )?.[0],
            }))
)

export const newOrdersWithStateCancelled = createSelector(ordersWithStateCancelled, (orders) => {
    const twoWeeksAgo = subDays(new Date(), 14)
    return orders.filter((order) => order.lastHistoryAction?.time && isAfter(order.lastHistoryAction.time, twoWeeksAgo))
})

export const oldOrdersWithStateCancelled = createSelector(ordersWithStateCancelled, (orders) => {
    const twoWeeksAgo = subDays(new Date(), 14)
    return orders.filter(
        (order) =>
            order.lastHistoryAction?.time &&
            (isBefore(order.lastHistoryAction.time, twoWeeksAgo) || isEqual(order.lastHistoryAction.time, twoWeeksAgo))
    )
})

export const ordersCount = createSelector(
    ordersWithStateReadyForSigning,
    ordersWithStateReadyForApproval,
    ordersWithStateInitialProcessing,
    ordersWithStateReadyForTransaction,
    ordersWithStateReadyForTransactionConfirmation,
    newOrdersWithStateCancelled,
    oldOrdersWithStateCancelled,
    (
        ordersWithStateReadyForSigning,
        ordersWithStateReadyForApproval,
        ordersWithStateInitialProcessing,
        ordersWithStateReadyForTransaction,
        ordersWithStateReadyForTransactionConfirmation,
        canceledOrders,
        oldCanceledOrders
    ) => {
        return {
            ordersWithStateInitialProcessing: ordersWithStateInitialProcessing.length,
            ordersWithStateReadyForSigning: ordersWithStateReadyForSigning.length,
            ordersWithStateReadyForApproval: ordersWithStateReadyForApproval.length,
            ordersWithStateReadyForTransaction: ordersWithStateReadyForTransaction.length,
            ordersWithStateReadyForTransactionConfirmation: ordersWithStateReadyForTransactionConfirmation.length,
            canceledOrders: canceledOrders.length,
            oldCanceledOrders: oldCanceledOrders.length,
        }
    }
)

export const bankInSession = createSelector(bankDataSelector, (bank) => bank)

export const partnerInSession = createSelector(partnerDataSelector, (partner) => partner)

export const isFixrateUser = createSelector(sessionSelector, (session) => {
    return session?.associations
        ?.find?.((association) => association.organisationType === 'FIXRATE')
        ?.roles?.includes('FIXRATE_ADMIN')
})

export const isFixrateRole = createSelector(sessionSelector, (session) => session?.organisationType === 'FIXRATE')

export const bankAssociation = createSelector(sessionSelector, (session) =>
    session?.associations.find((association) => association.organisation?.type === 'BANK')
)

export const isDepositorWithOfficialRating = createSelector(
    sessionSelector,
    depositorsDataSelector,
    (session, depositors) => {
        return session.associations.some((association) => {
            const id = association.organisation && association.organisation.id
            if (association.organisation && association.organisation.type === 'DEPOSITOR') {
                const depositor = depositors.find((d) => d.id === id)
                return depositor && depositor.premiumProducts.includes('OFFICIAL_RATING')
            } else {
                return false
            }
        })
    }
)

export const organisationCanBuyFunds = createSelector(depositorDataSelector, (depositor) => {
    return depositor?.fundData?.buyEnabled
})

export const userCanBuyFunds = createSelector(sessionSelector, depositorDataSelector, (session, depositor) => {
    const association = session.associations.find((association) => association.organisation?.id === depositor?.id)
    return association?.permissions?.includes('DEPOSITOR__ORDER__CREATE') // && association?.permissions?.includes('DEPOSITOR__ACCOUNT_AGREEMENT__SIGN')
})

export const userCanConfirmPayment = createSelector(sessionSelector, depositorDataSelector, (session, depositor) => {
    const association = session.associations.find((association) => association.organisation?.id === depositor?.id)
    return association?.permissions?.includes('DEPOSITOR__PAYMENT__CONFIRM')
})

export const isBankWithExtendedAnalytics = createSelector(sessionSelector, bankDataSelector, (session, bank) => {
    return (
        bank?.premiumProducts?.includes?.('EXTENDED_INTEREST_ANALYTICS') &&
        Boolean(session?.associations?.some?.((association) => association?.organisation?.type === 'BANK'))
    )
})

export const isBankWithAnalytics = createSelector(sessionSelector, bankDataSelector, (session, bank) => {
    return session.associations.some((association) => {
        if (association.organisation && association.organisation.type === 'BANK') {
            return bank && bank.premiumProducts.includes('INTEREST_ANALYTICS')
        } else {
            return false
        }
    })
})

export const canSetPinCode = createSelector(
    sessionSelector,
    bankDataSelector,
    depositorsDataSelector,
    (session, bank, depositors) => {
        return session.associations.every((association) => {
            if (association.organisationType === 'FIXRATE') {
                return association.roles.includes('FIXRATE_ADMIN')
            }
            const id = association.organisation.id
            const type = association.organisation.type
            if (type === 'BANK') {
                return bank && bank.allowPincodes
            } else if (type === 'DEPOSITOR') {
                const depositor = depositors.find((d) => d.id === id)
                return depositor && depositor.allowPincodes
            } else {
                return false
            }
        })
    }
)

export const bankNames = createSelector(banksDataSelector, (banks) =>
    Object.keys(banks).reduce((acc, bankId) => {
        acc[bankId] = banks[bankId].name
        return acc
    }, {})
)

export const depositorSettlementAccounts = createSelector(depositorsDataSelector, (depositors) => {
    return depositors.reduce((acc, depositor) => {
        acc[depositor.id] = depositor.settlementAccounts
        return acc
    }, {}) as { [key: string]: SettlementAccountDto[] }
})

export const settlementAccountMap = createSelector(depositorsDataSelector, (depositors) => {
    return depositors.reduce((acc, depositor) => {
        depositor.settlementAccounts.forEach((settlementAccount) => {
            acc[settlementAccount.id] = settlementAccount
        })
        return acc
    }, {}) as { [key: string]: SettlementAccountDto }
})

type DepositorUserInvitesMap = { [key: string]: OrganisationUserInviteDto[] }
export const depositorUserInvites = createSelector(depositorsDataSelector, (depositors) => {
    return depositors.reduce<DepositorUserInvitesMap>((acc, depositor) => {
        acc[depositor.id] = depositor.userInvites
        return acc
    }, {})
})

export const lookupDepositorUserInvite = createSelector(
    depositorUserInvites,
    (depositorUserInvites) => (depositorId: string, inviteId: string) => {
        const list = depositorUserInvites[depositorId] || []
        return list.find((invite) => invite.id === inviteId)
    }
)

export const bankUsers = createSelector(bankDataSelector, (bank) => {
    if (!bank) return []
    return bank.users
})

export const bankUserInvites = createSelector(bankDataSelector, (bank) => {
    if (!bank) return []
    return bank.userInvites
})

export const partnerUsers = createSelector(partnerDataSelector, (partner) => {
    if (!partner) return []
    return partner.users
})

export const partnerUserInvites = createSelector(partnerDataSelector, (partner) => {
    if (!partner) return []
    return partner.userInvites
})

export interface DepositGroupObject {
    i18nKey: string
    deposits: DepositDto[]
    expired?: boolean
    expiresSoon?: boolean
}

export interface GroupedDeposits {
    groups: DepositGroupObject[]
    archived: DepositDto[]
}

export const groupedDepositsByProductType: (state: RootState) => GroupedDeposits = createSelector(
    (state: RootState) => state.deposits,
    portfolioFilterSelector,
    (deposits, portfolioFilter) => {
        const filteredDeposits = { current: deposits }

        const initialValue: GroupedDeposits = {
            groups: [
                { i18nKey: 'expiredDeposits', deposits: [], expired: true },
                { i18nKey: 'productId1', deposits: [] },
                { i18nKey: 'productId2', deposits: [] },
                { i18nKey: 'productId3', deposits: [] },
                { i18nKey: 'productId4', deposits: [] },
                { i18nKey: 'productId5', deposits: [] },
                { i18nKey: 'productId6', deposits: [] },
                { i18nKey: 'productId7', deposits: [] },
                { i18nKey: 'productId8', deposits: [] },
                { i18nKey: 'productId9', deposits: [] },
                { i18nKey: 'productId10', deposits: [] },
                { i18nKey: 'productId11', deposits: [] },
                { i18nKey: 'productId12', deposits: [] },
                { i18nKey: 'externalDeposits', deposits: [] },
            ],
            archived: [],
        }

        let hasExpired: boolean
        let managed: boolean
        let productId: string

        if (!Array.isArray(filteredDeposits.current)) {
            return initialValue
        }

        if (portfolioFilter?.bankId) {
            filteredDeposits.current = filteredDeposits.current.filter(
                (deposit) => deposit?.bankId === portfolioFilter.bankId
            )
        }

        if (portfolioFilter?.depositorId) {
            filteredDeposits.current = filteredDeposits.current.filter(
                (deposit) => deposit?.depositor?.id === portfolioFilter.depositorId
            )
        }

        if (portfolioFilter?.tagText) {
            filteredDeposits.current = filteredDeposits.current.filter(
                (deposit) => deposit?.tagText === portfolioFilter.tagText
            )
        }

        const result: GroupedDeposits = filteredDeposits.current.reduce((result, currentDeposit) => {
            productId = currentDeposit?.product?.id
            managed = currentDeposit?.managed
            hasExpired = currentDeposit.expires?.expired

            function pushDeposit(group: string | number) {
                if (result?.groups?.[group]?.deposits) {
                    result.groups[group]?.deposits.push(currentDeposit)
                }
            }

            if (!managed) {
                pushDeposit(6)
            } else if (hasExpired) {
                result.archived.push(currentDeposit)
            } else {
                pushDeposit(productId)
            }

            return result
        }, initialValue)

        result.groups.sort((a, b) => b.deposits.length - a.deposits.length)

        return result
    }
)

export const depositorHasActiveDeposits = createSelector(depositsSelector, (deposits) => (depositorId: string) => {
    return deposits.filter((deposit) => deposit.depositor.id === depositorId).length > 0
})

export const lookupRolesForBank = createSelector(sessionSelector, (session) => {
    const association = session.associations.find(
        (association) => association.organisation && association.organisation.type === 'BANK'
    )
    if (!association) return []

    return association.roles
})

export const lookupRolesForPartner = createSelector(sessionSelector, (session) => {
    const association = session.associations.find(
        (association) => association.organisation && association.organisation.type === 'PARTNER'
    )
    if (!association) return []

    return association.roles
})

const signatureStatus = (state: RootState) => state.signatureStatusIsLoading
export const lookupSignatureStatus = createSelector(
    signatureStatus,
    (signatureStatusIsLoading) => (identity: string) => {
        return signatureStatusIsLoading[identity] ? signatureStatusIsLoading[identity] : false
    }
)

export const isCheckingSignatureStatusForAnyDocument = createSelector(signatureStatus, (signatureStatusIsLoading) => {
    const keys = Object.keys(signatureStatusIsLoading)
    return keys.filter((key) => signatureStatusIsLoading[key]).length > 0
})

export const profileSelector = createSelector(sessionSelector, (session) => {
    return {
        fullName: `${session.firstName} ${session.lastName}`,
    }
})

export const depositorAssociations = createSelector(userAssociationsSelector, (associations) => {
    if (!associations) return []
    return associations.filter(
        (association) => association.organisation && association.organisation.type === 'DEPOSITOR'
    )
})

export const lookupBankDocumentsForDeposit = createSelector(
    bankDocumentsDataSelector,
    (bankDocuments) => (depositId: string) => bankDocuments.filter((document) => document.depositId === depositId)
)

export const annualStatements = createSelector(bankDocumentsDataSelector, (bankDocuments) =>
    bankDocuments.filter(
        (document) => document.documentType === 'BANK_DOCUMENT' && document.type === 'YEARLY_STATEMENT'
    )
)

export const TerminationState = {
    STARTED: 'STARTED',
    DOCUMENT_SIGNED: 'DOCUMENT_SIGNED',
    SENT_TO_BANK: 'SENT_TO_BANK',
    COMPLETED: 'COMPLETED',
    CONFIRMED: 'CONFIRMED',
    NO_ACTIVE_TERMINATION: 'NO_ACTIVE_TERMINATION',
}

export const documentIdToDocumentMapper = createSelector(
    documentsSelector,
    (documents) => (documentIdList: string[]) => {
        return documentIdList
            .map((documentId) => {
                const document = documents[documentId]
                /* For debugging */
                if (!document) {
                    console.error('Warning: Document ' + documentId + ' is missing')
                }
                return document
            })
            .filter((document) => !!document)
            .slice()
            .sort(
                (a, b) =>
                    a.created &&
                    b.created &&
                    // DocumentDto::created is converted to a Date object in websocketMessageHandler.ts
                    // so the type from DocumentDto is not correct here
                    (a.created as unknown as Date).getTime() - (b.created as unknown as Date).getTime()
            )
    }
)

export const lookupDocument = createSelector(
    documentsSelector,
    (documents) => (documentId: string) => documents[documentId]
)

export const signedAuthorizationDocumentIsMissing = createSelector(
    sessionSelector,
    depositorsDataSelector,
    documentsSelector,
    (session, depositors, documents) => {
        return depositors.reduce<{ [depositorId: string]: boolean }>((acc, depositor) => {
            acc[depositor.id] =
                depositor.users
                    .filter((user) => user.id === session.id)
                    .filter(
                        (user) =>
                            userIsAccountCreator(user) &&
                            !user.roles.includes('DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION')
                    )
                    .filter((user) => {
                        const authDoc = user.authorizationDocumentId && documents[user.authorizationDocumentId]
                        return !authDoc || !authDoc.signedByAll
                    }).length > 0
            return acc
        }, {})
    }
)

export const userNeedsIdDoc = createSelector(depositorsDataSelector, sessionSelector, (depositors, session) => {
    return !!depositors.find((depositor) => {
        const user = depositor.users.find((user) => user.id === session.id)
        return user && userIsAccountCreator(user)
    })
})

export type InterestRateChangeExtendedDto = InterestRateChangeDto & { deposit: DepositDto; bank: BankDto }

export const allInterestRateChanges = createSelector(
    interestRateChangeSelector,
    depositsSelector,
    banksDataSelector,
    (interestRateChangeList, deposits, banks) => {
        if (deposits.length === 0 || Object.keys(banks).length === 0) {
            return []
        }

        return interestRateChangeList
            .filter((irc) => !irc.cancelled)
            .map((irc) => ({
                irc,
                deposit: deposits.find((d) => d.id === irc.depositId), // Lookup deposit
            }))
            .filter(({ deposit }) => !!deposit) // Make sure deposit exists
            .map(({ irc, deposit }) => {
                return {
                    ...irc,
                    deposit: deposit,
                    bank: banks[deposit.bankId],
                } as InterestRateChangeExtendedDto
            })
    }
)

export const depositsWithActiveOrUnsentInterestRateChanges = createSelector(
    interestRateChangeSelector,
    depositsSelector,
    banksDataSelector,
    (interestRateChangeList, deposits, banks) => {
        if (deposits.length === 0 || Object.keys(banks).length === 0) {
            return []
        }

        return interestRateChangeList
            .filter((irc) => !irc.cancelled)
            .filter((irc) => !(irc.implemented || deposits[irc.depositId]?.expires?.expired))
            .map((irc) => irc.depositId)
    }
)

export const identificationDocument = createSelector(
    documentsSelector,
    identificationDocumentIdSelector,
    (documents, documentId) => documents[documentId]
)

export const authorizationDocumentsToSign = createSelector(
    depositorNamesDataSelector,
    documentsSelector,
    (depositors, documents) =>
        Object.values(documents)
            .filter(
                (doc) =>
                    doc.documentType === 'AUTHORIZATION_DOCUMENT' &&
                    doc.ownerType === 'DEPOSITOR' &&
                    (!!doc.signedByUser || !!doc.userCanSign)
            )
            .map((doc) => ({
                ...doc,
                depositorName: depositors[doc.owner],
                depositorId: doc.owner,
            }))
)

export const messageTaskActionRequiredByUserSelector = createSelector(
    userAssociationMap,
    (state: RootState) => state?.session?.id,
    (associationMap, sessionId) => (task: TaskDto) => {
        return (
            task.resolved === false &&
            (associationMap[task.targetOrganisationId]?.roles.includes(task.targetRole) ||
                sessionId === task.targetUserId)
        )
    }
)

export const messageTasksActionRequiredByUserCountSelector = createSelector(
    messageTaskActionRequiredByUserSelector,
    (state: RootState) => state?.messages,
    (messageTaskActionRequiredByUser, messages) => {
        return messages.filter((message) => messageTaskActionRequiredByUser(message?.task)).length
    }
)

export const missingAccountStatementsSelector = createSelector(
    depositsByIdSelector,
    (state: RootState) => state?.missingAccountStatements,
    (depositsById, missingAccountStatements) =>
        missingAccountStatements.map((mas) => ({
            depositId: mas.depositId,
            depositorName: depositsById[mas.depositId]?.depositor?.name,
            lastReport: mas.lastReport,
            forMonth: mas.forMonth,
            accountNo: depositsById[mas.depositId]?.account,
        }))
)

export const isDepositorSelector = createSelector(sessionSelector, (session) => {
    return session?.association?.roles?.includes('DEPOSITOR_VIEW')
})

export const hasFundAccessSelector = createSelector(
    (state: RootState) => state.depositors,
    (depositors) => depositors.some((depositor) => depositor.fundData?.enabled)
)

export const isFundIntermediaryAdminSelector = createSelector(sessionSelector, (session) => {
    return session?.association?.roles?.includes('FUND_INTERMEDIARY_ADMIN')
})

export const canBuyFundsSelector = createSelector(
    (state: RootState) => state.depositors,
    (depositors) => depositors.some((depositor) => depositor.fundData?.buyEnabled)
)

export const fundPlacementsShareClassInfoSelector = createSelector(
    (state: RootState) => state.funds,
    (state: RootState) => state.fundPlacements,
    (funds, fundPlacements): { [index: string]: ShareClassInfo } => {
        const fundNames: { [fundShareClassId: string]: ShareClassInfo } = {}
        Object.values(funds)
            .flatMap((fund) => fund.fundShareClasses)
            .forEach((fsc) => {
                fundNames[fsc.id] = { shareClassId: fsc.id, isin: fsc.isin, fullName: fsc.fullName }
            })

        const result: { [fundPlacementId: string]: ShareClassInfo } = {}

        Object.values(fundPlacements).forEach((fundPlacement) => {
            result[fundPlacement.id] = fundNames[fundPlacement.fundShareClassId]
        })
        return result
    }
)

export const lastNavDateSelector = createSelector(
    (state: RootState) => state.funds,
    (state: RootState) => state.fundPlacements,
    (funds, fundPlacements) => {
        const fundNavDateByShareClass = funds
            .flatMap((fund) => fund.fundShareClasses)
            .reduce(
                (result, fundShareClass) => {
                    result[fundShareClass.id] = fundShareClass.navDate
                    return result
                },
                {} as { [fundShareClassId: string]: string }
            )

        return fundPlacements
            .map((fundPlacement) => fundNavDateByShareClass[fundPlacement.fundShareClassId])
            .filter((navDate) => navDate !== undefined)
            .reduce((result, navDate) => (result === undefined || navDate < result ? navDate : result), undefined)
    }
)

export const dividendFundIdsSelector = createSelector(
    (state: RootState) => state.funds,
    (funds) => funds.filter((fund) => fund.dividendFund).map((fund) => fund.id)
)

export const dividendFundPlacementsSelector = createSelector(
    dividendFundIdsSelector,
    (state: RootState) => state.fundPlacements,
    (dividendFundIds, fundPlacements) =>
        fundPlacements.filter((fundPlacement) => dividendFundIds.includes(fundPlacement.fundId))
)

export const fundPlacementsWithMissingDividendsFromLastYearSelector = createSelector(
    dividendFundPlacementsSelector,
    (dividendFundPlacements) => {
        const currentYear = new Date().getFullYear()
        const currentYearJan1 = currentYear + '-01-01'
        const lastYearDec31 = currentYear - 1 + '-12-31'
        return dividendFundPlacements.filter(
            (fp) =>
                fp.startOfLatestInvestmentPeriod != null &&
                isBefore(fp.startOfLatestInvestmentPeriod, currentYearJan1) && // Only include placements that were opened before this year.
                fp.placementParts.length > 0 && // Only include placements that are not empty.
                !fp.transactions.some((t) => t.transactionDate === lastYearDec31 && t.type === 'DIVIDEND') // Only include placements that do not have a dividend transaction for the previous year.
        )
    }
)

export const fundReportMaxEndDateSelector = createSelector(
    lastNavDateSelector,
    fundPlacementsWithMissingDividendsFromLastYearSelector,
    (lastCommonNavDate, fundPlacementsWithMissingDividends) => {
        if (!lastCommonNavDate) {
            return undefined
        }
        const currentYear = new Date().getFullYear()
        const lastYear = currentYear - 1
        const lastYearDec30 = lastYear + '-12-30'
        if (isBefore(lastCommonNavDate, lastYearDec30)) {
            // If the last common nav date is before 12-30, the later checks are not needed.
            return lastCommonNavDate
        }
        if (fundPlacementsWithMissingDividends.length > 0) {
            // If there are placements with missing dividends from last year, we can not show reports that include 12-31.
            return lastYearDec30
        }
        // Otherwise we can show reports up to the last common nav date.
        return lastCommonNavDate
    }
)

export const currentPortfolio = createSelector(sessionSelector, depositorsDataSelector, (session) => {
    const association = session.association
    if (!association) return undefined
    return association.currentPortfolio
})

interface BaseRiskRowData {
    interestRateSensitivity: number | null
    creditSensitivity: number | null
    standardDeviation: number | null
    weightedAverageCoupon: number | null
    marketValue: number
    portfolioWeight: number
}

export type FundPlacementRiskRowData = BaseRiskRowData & {
    fundPlacementId: string
    fullFundName: string | null
}

export const fundPlacementsRiskRowDataSelector = createSelector(
    (state: RootState) => state.fundPlacements.filter((fp) => fp.unitQuantity !== 0),
    (state: RootState) => state.funds,
    (fundPlacements, funds): FundPlacementRiskRowData[] => {
        const totalValue = fundPlacements.reduce((acc, placement) => acc + placement.currentValue, 0)
        return fundPlacements
            .filter((placement) => placement.unitQuantity !== 0)
            .map((placement) => {
                const fund = funds.find((fund) => fund.id === placement.fundId)
                const fsc = fund?.fundShareClasses.find((fsc) => fsc.id === placement.fundShareClassId)
                const marketValue = placement.currentValue
                const portfolioWeight = totalValue ? (marketValue / totalValue) * 100 : 0

                return {
                    fundPlacementId: placement.id,
                    fullFundName: fsc?.fullName || null,
                    interestRateSensitivity: fund?.interestRateSensitivity || null,
                    creditSensitivity: fund?.creditSensitivity || null,
                    standardDeviation: fsc?.standardDeviation || null,
                    weightedAverageCoupon: fund?.weightedAverageCoupon || null,
                    marketValue: marketValue,
                    portfolioWeight: portfolioWeight,
                }
            })
    }
)

export type FundPortfolioRiskData = {
    interestRateSensitivity: number | null
    creditSensitivity: number | null
    standardDeviation: number | null
    weightedAverageCoupon: number | null
    totalValue: number
    totalWeight: number
}

export const fundPortfolioRiskDataSelector = createSelector(
    fundPlacementsRiskRowDataSelector,
    (rows): FundPortfolioRiskData => {
        const weightedAverage = (key: keyof BaseRiskRowData) => {
            const totalValueForRisk = rows.reduce((acc, row) => {
                if (row[key] == null) {
                    return acc
                }
                return acc + row.marketValue
            }, 0)
            if (totalValueForRisk === 0) {
                return null
            }
            const weightedSum = rows.reduce((acc, row) => {
                if (row[key] == null) {
                    return acc
                }
                return acc + row[key] * row.marketValue
            }, 0)
            return weightedSum / totalValueForRisk
        }
        const totalValue = rows.reduce((acc, row) => acc + row.marketValue, 0)
        const totalWeight = rows.reduce((acc, row) => acc + row.portfolioWeight, 0)

        return {
            interestRateSensitivity: weightedAverage('interestRateSensitivity'),
            creditSensitivity: weightedAverage('creditSensitivity'),
            standardDeviation: weightedAverage('standardDeviation'),
            weightedAverageCoupon: weightedAverage('weightedAverageCoupon'),
            totalValue: totalValue,
            totalWeight: totalWeight,
        }
    }
)
