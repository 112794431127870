import { OnboardingWizardStatus } from '#app/utilities/useOnboardingWizardStatus'
import { LinearProgress, Stack, Typography } from '@mui/material'
import { PURPLE } from '#app/colors/colors'
import InterestOutput from '#components/InterestOutput'
import { useTranslation } from 'react-i18next'

const OnboardingProgress = ({ onboardingWizardStatus }: { onboardingWizardStatus: OnboardingWizardStatus }) => {
    const { t } = useTranslation()

    return (
        <>
            <Typography variant="h4" data-cy="wizardProgressTitle">
                {onboardingWizardStatus?.progress < 100 ? t('menu.finishRegistration') : t('menu.registrationFinished')}
            </Typography>
            <Stack mt={0.5} width="100%">
                <Typography data-cy="wizardProgress" variant="caption" fontWeight="700" color={PURPLE[500]}>
                    {InterestOutput.formatWithDecimals(onboardingWizardStatus.progress, 0)}{' '}
                    {t('pages-OrganizationWizard.statusFinished')}
                </Typography>
                <LinearProgress
                    sx={{ width: '100%', marginTop: '0.6rem', height: '0.8rem', borderRadius: '10rem' }}
                    variant={'determinate'}
                    value={onboardingWizardStatus.progress}
                />
            </Stack>
        </>
    )
}

export default OnboardingProgress
