import { COLORS, PURPLE } from '#app/colors/colors'
import { useCurrencyOutputWithCurrency } from '#app/components/CurrencyOutput/useCurrencyOutput'
import FundsDevelopmentChart from '#app/pages/portfolio-depositor/Reports/FundReports/InventoryReport/FundsDevelopmentChart'
import InventoryTable from '#app/pages/portfolio-depositor/Reports/FundReports/InventoryReport/InventoryTable'
import PortfolioSplitChart from '#app/pages/portfolio-depositor/Reports/FundReports/InventoryReport/PortfolioSplitChart'
import ReturnsTable from '#app/pages/portfolio-depositor/Reports/FundReports/InventoryReport/ReturnsTable'
import { DateOutput, LoadingSpinner, PageHeader, PageLayout } from '#components'
import { InterestBadge } from '#components/InterestBadge/InterestBadge'
import FixrateLogo from '#pages/menu/FixrateLogo'
import styles from '#pages/portfolio-depositor/Reports/FundReports/AccountingReport/AccountingReport.module.scss'
import { formatLongReportPeriod } from '#pages/portfolio-depositor/Reports/ReportPeriods'
import { FundInventoryReportPdfProps } from '@fixrate/fixrate-report'
import { useSelector } from '#state/useSelector'
import {
    Alert,
    Box,
    Button,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material'
import { Fragment, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import EmptyState from '#components/EmptyState/EmptyState'
import usePortfolio from "#services/usePortfolio";
import { ReportVersionSelect } from "#pages/portfolio-depositor/Reports/FundReports/components/ReportVersionSelect";
import { useFundInventoryReport } from "#pages/portfolio-depositor/Reports/FundReports/useFundReports";
import { shallowEqual } from 'react-redux'

type Params = {
    period?: string
}

export default function InventoryReportPage() {
    const { t } = useTranslation()
    const { period } = useParams<Params>()
    const {depositorId, depositorName} = useSelector((state) => {
        const depositor = state.depositor
        return { depositorId: depositor?.id, depositorName: depositor?.name }
    }, shallowEqual)
    const {portfolioId, portfolioName} = useSelector((state) => {
        const portfolio = state.session?.association?.currentPortfolio
        return { portfolioId: portfolio?.id, portfolioName: portfolio?.name }
    }, shallowEqual)
    const [searchParams, setSearchParams] = useSearchParams()
    const selectedVersion = searchParams.get('version')
    const {
        report,
        isSuccess: reportSuccess,
        isLoading: reportLoading,
        isError: reportError,
    } = useFundInventoryReport(depositorId, portfolioId, period, selectedVersion)
    const portfolioIdRef = useRef<string | null>(portfolioId)

    if (portfolioIdRef.current !== portfolioId) {
        portfolioIdRef.current = portfolioId
        setSearchParams({})
    }

    const versionSuffix = selectedVersion ? `&version=${selectedVersion}` : ''
    const downloadPdfLink = `/api/report/fund-inventory/pdf?depositorId=${depositorId}&period=${period}&portfolioId=${portfolioId}&download=attachment${versionSuffix}`

    function changeVersion(newVersion: string) {
        if (newVersion !== selectedVersion) {
            setSearchParams({version: newVersion})
        }
    }

    return (
        <Fragment>
            <PageHeader
                backToLink={'/reports/funds'}
                icon="ri-line-chart-line"
                title={t('pages-fund-reports.fundReports')}
            ></PageHeader>
            {reportLoading && <LoadingSpinner />}
            {reportError && <Alert severity="error">{t('pages-fund-reports.errorLoadingReport')}</Alert>}
            {reportSuccess && <InventoryReport
                    report={report}
                    downloadPdfLink={downloadPdfLink}
                    changeVersion={changeVersion}
                    period={period}
                    depositorName={depositorName}
                    portfolioName={portfolioName}
                />}
        </Fragment>
    )
}

type InventoryReportProps = FundInventoryReportPdfProps & {
    downloadPdfLink?: string
    pdfMode?: boolean
    changeVersion?: (newVersion: string) => void,
    period?: string
}
export function InventoryReport({ report, depositorName, portfolioName, downloadPdfLink, pdfMode = false, changeVersion, period, }: InventoryReportProps) {
    const { t } = useTranslation()
    const currency = report?.currency
    const periodIdentifier = report?.periodIdentifier || period
    const Currency = useCurrencyOutputWithCurrency(currency)
    const noFundsInPeriod = report?.fundPlacementInventoryEntries.length === 0
    const isPrint = useMediaQuery('print') || pdfMode
    const version = report?.version ? `${report.version}` : undefined
    const timestamp = report?.updated || undefined
    const reportDescription = formatLongReportPeriod(periodIdentifier, t) + (isPrint ? ` – ${depositorName} – ${portfolioName}` : '')

    return (
        <PageLayout style={{ backgroundColor: 'white' }}>
            {isPrint && (
                <Box display={'flex'} justifyContent={'right'}>
                    <FixrateLogo className={styles.fixratePdfHeaderLogo} />
                </Box>
            )}
            <Stack spacing={4} sx={{ px: { xs: 2, md: 0 }, maxWidth: '130rem' }}>
                <Stack>
                    {periodIdentifier && (
                        <Box
                            sx={{
                                color: COLORS.PURPLE,
                                fontWeight: 600,
                                fontVariant: 'small-caps',
                                textTransform: 'uppercase',
                                mb: 1,
                            }}
                        >
                            {reportDescription}
                        </Box>
                    )}
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant={'h1'}>{t('pages-fund-reports.inventoryReport')}</Typography>
                        {(version !== undefined && timestamp !== undefined) && (
                            <ReportVersionSelect
                                reportType={"INVENTORY"}
                                depositorId={report?.depositorId}
                                portfolioId={report?.portfolioId}
                                period={period}
                                currentVersion={version}
                                currentTimestamp={timestamp}
                                changeVersion={changeVersion}
                                isPrint={isPrint}
                            />
                        )}
                    </Stack>
                </Stack>
                {!report && (
                    <EmptyState
                        illustration={{ name: 'manAnalyzing', color: 'default' }}
                        title={t('pages-fund-reports.noReportForPeriod')}
                    />                )}
                {report && (
                    <Stack spacing={6}>
                        <Stack spacing={3}>
                            <Typography variant={'h2'}>{t('pages-fund-inventory-report.portfolio')}</Typography>
                            <Stack
                                direction={{ xs: 'column-reverse', lg: 'row' }}
                                justifyContent={'flex-start'}
                                spacing={4}
                            >
                                {noFundsInPeriod && <p>{t('pages-fund-inventory-report.portfolioEmptyState')}</p>}
                                <PortfolioSplitChart report={report} pdfMode={isPrint} />
                                <Stack spacing={2}>
                                    <Stack spacing={1}>
                                        <Box component="span" sx={{ color: COLORS.SILVER_GRAY, fontWeight: 500 }}>
                                            {t('pages-fund-inventory-report.valuePerDate', {
                                                date: DateOutput.formatDate(report.toDate),
                                            })}
                                        </Box>
                                        <Box component="span" sx={{ fontSize: 22, fontWeight: 900 }}>
                                            {Currency(report.outgoingBalance)}
                                        </Box>
                                    </Stack>
                                    <Stack spacing={0.5}>
                                        <Box component="span" sx={{ color: COLORS.SILVER_GRAY, fontWeight: 500 }}>
                                            {t('pages-fund-inventory-report.developmentInPeriod')}
                                        </Box>
                                        <Stack
                                            component="span"
                                            direction={'row'}
                                            spacing={1}
                                            alignItems={'center'}
                                            sx={{ fontSize: 22, fontWeight: 900 }}
                                        >
                                            {report.returnRates.CURRENT && (
                                                <InterestBadge
                                                    sx={{ fontSize: '1.6rem' }}
                                                    interest={report.returnRates.CURRENT}
                                                />
                                            )}
                                            <Box component="span">{Currency(report.returnValues.CURRENT)}</Box>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Box sx={{pageBreakInside: 'avoid', breakInside: 'avoid'}}>
                            <InventoryTable report={report} currency={currency} />
                        </Box>
                        <Stack spacing={2} sx={{ breakInside: 'avoid', pageBreakInside: 'avoid'}}>
                            <Typography variant={'h2'}>
                                {t('pages-fund-inventory-report.fundsDevelopmentInPeriod')}
                            </Typography>
                            <Box>
                                <Typography sx={{ mb: 2 }} fontWeight={600} fontSize={'1.8rem'}>
                                    {t('pages-fund-inventory-report.timeWeightedReturn')}
                                    {!isPrint && (
                                        <Tooltip arrow title={t('pages-fund-inventory-report.timeWeightedReturnInfo')}>
                                            <i
                                                className="ri-question-line"
                                                style={{ marginLeft: '0.4rem', color: PURPLE[500] }}
                                            />
                                        </Tooltip>
                                    )}
                                </Typography>
                                <FundsDevelopmentChart report={report} pdfMode={isPrint} />
                            </Box>
                        </Stack>
                        <Stack spacing={2}>
                            <Box sx={{ breakInside: 'avoid', pageBreakInside: 'avoid'}}>
                                <Typography variant={'h2'}>
                                    {t('pages-fund-inventory-report.moneyWeightedReturn')}
                                    {!isPrint && (
                                        <Tooltip arrow title={t('pages-fund-inventory-report.moneyWeightedReturnInfo')}>
                                            <i
                                                className="ri-question-line"
                                                style={{ marginLeft: '0.4rem', color: PURPLE[500] }}
                                            />
                                        </Tooltip>
                                    )}
                                </Typography>
                                <Box sx={{ maxWidth: '92rem', pageBreakInside: 'avoid', breakInside: 'avoid'}}>
                                    <Typography sx={{ mb: 2 }} fontWeight={600} fontSize={'1.8rem'}>
                                        {t('pages-fund-inventory-report.returnsInPercent')}
                                    </Typography>
                                    <ReturnsTable type={'returnRates'} report={report} currency={currency} />
                                </Box>
                            </Box>
                            <Box sx={{ pageBreakInside: 'avoid', breakInside: 'avoid' }}>
                                <Typography sx={{ mb: 2 }} fontWeight={600} fontSize={'1.8rem'}>
                                    {t('pages-fund-inventory-report.returnsInCurrency', {currency: currency})}
                                </Typography>
                                <ReturnsTable type={'returnValues'} report={report} currency={currency} />
                            </Box>
                        </Stack>
                        {!isPrint && downloadPdfLink && (
                            <Box>
                                <Button
                                    href={downloadPdfLink}
                                    startIcon={<i className="ri-file-pdf-2-line"></i>}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    variant={'contained'}
                                >
                                    {t('pages-fund-inventory-report.downloadAsPdf')}
                                </Button>
                            </Box>
                        )}
                    </Stack>
                )}
            </Stack>
        </PageLayout>
    )
}
